import React from "react"
import logo from "../assets/images/logo.svg"
import { Link } from "gatsby"
import { Container } from "../styles/globalStyles"
import styled from "styled-components"




const Header = () => {
  return (
    <Navbar>
      <Container className="navContainer">

        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>

        {/* <PageLinks styleClass="nav"></PageLinks> */}

        {/* <Link to="/contact" className="button is-secondary small">Contact Us</Link> */}

      </Container>
    </Navbar>
  )
}

export default Header


const Navbar = styled.header`
    position:absolute;
    width: 100%;
    z-index: 99;
    padding-top: 3.2rem;
    display: flex;
    align-items: center;
`