import styled, { css } from "styled-components";
import { device } from '../../styles/globalStyles'


// Typescale 1.5 - Perfect Fit

// 5.063rem/81.00px	A Visual Type Scale
// 3.375rem/54.00px	A Visual Type Scale
// 2.25rem/36.00px	A Visual Type Scale
// 1.5rem/24.00px

// Typescale 1.33 - Perfect Fourth
// 4.209rem/6.4 A Visual Type Scale
// 3.157rem/48px A Visual Type Scale
// 2.369rem/32px A Visual Type Scale
// 1.777rem/24px A Visual Type Scale
// 1.333rem/21.33px A Visual Type Scale
// 1rem/16.00px 

// Typescale 1.2 - Small Screen
// 4.209rem/6.4 A Visual Type Scale
// 3.157rem/48px A Visual Type Scale
// 2.369rem/32px A Visual Type Scale
// 1.777rem/24px A Visual Type Scale
// 1.333rem/21.33px A Visual Type Scale
// 1rem/16.00px 

export const H1 = styled.h1`
  font-size: 8rem;
  font-weight: 900;
  line-height: 8rem;
  letter-spacing: -3px;

  @media ${device.tabletL}  {
    font-size: 6.4rem;
    line-height:6.4rem;
  }
  @media (max-width: 567px) {
    font-size: 4rem;
    line-height:4rem;
  }
`;

export const H2 = styled.h2`
  font-size: 5.4rem;
  font-weight: 900;
  line-height: 5.6rem;
  letter-spacing: -2px;
  @media ${device.tabletL}{
    font-size: 4.8rem;
    line-height: 4.8rem;
    letter-spacing: -1px;
  }
  @media (max-width: 567px) {
    font-size: 3.2rem;
    line-height:3.2rem;
    letter-spacing: -0.5px;
  }
`;

export const Gradient = styled.span`
  display: inline-block;
  background: linear-gradient(90deg, #aed9aa 0%, #4db390 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding: 2px;
  padding-left: 0;
`;

export const H3 = styled.h3`
  font-size: 3.6rem;
  font-weight: 900;
  line-height: 4rem;
  letter-spacing: -2px;

  @media ${device.tabletL} {
    font-size: 3.2rem;
    line-height:3.6rem;
    letter-spacing: -0.5px;
  }
`;

export const H4 = styled.h4`
  font-size: 2.4rem;
  font-weight: 900;
  line-height: 2.4rem;
  letter-spacing: 0;

  @media ${device.tabletL} {
    font-size: 2rem;
    line-height:2.4rem;
    letter-spacing: 0;
  }


`;
export const H5 = styled.h5`
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 2.4rem;
  letter-spacing: 0;


  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;

      @media ${device.mobileL} {
        font-size:1.4rem;
        line-height:2rem;
      }
    `}

  ${(props) =>
    props.green &&
    css`
      color: #aed9aa;
    `}
`;
export const H6 = styled.h6`
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const P = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0;


  ${(props) =>
    props.large &&
    css`
      font-size: 1.8rem;
      line-height: 3rem;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 1.4rem;
      line-height: 2rem;
    `}
`;
