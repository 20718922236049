import styled, { css } from "styled-components";
import { motion } from "framer-motion";

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '567px',
  tablet: '768px',
  tabletL: '991px',
  laptop: '1024px',
  laptopL: '1440px'
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`
}


export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 128rem) {
         padding:0 2rem;   
  }

  @media ${device.mobileM}{
    padding:0 1.5rem;  
  }

  ${(props) =>
    props.inner &&
    css`
      max-width: 106rem;
      margin: 0 auto;

      /* @media (max-width: 106rem) {
         padding:0 2rem;   
  } */
    `}
`;

export const LargeText = styled.p`
  font-size: 1.8rem;
  line-height: 3rem;
  margin-bottom: 1rem;
`;

export const Icon = styled.div`
  display: grid;
  height: 7rem;
  width: 7rem;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.05); */
  /* box-shadow: inset 0px 0px 2px rgba(255, 255, 255, 0.5); */
  /* border-radius: 1.6rem; */
`;

export const Button = styled(motion.button)``;


