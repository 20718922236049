import React from "react";
import { Container } from "../styles/globalStyles";
import { P } from "../components/styles/TextStyles";
import styled from "styled-components";
import logo from "../assets/images/logo.svg";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <FooterComponent>
      <Container inner>
        <FooterTop>
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
          <Links>
            <List>

              <a href="https://codewellgroup.com" target="_blank" rel="noreferrer" >
                <Paragraph small>About Us</Paragraph>
              </a>

              {/* <Link to="/pricing">
              <Paragraph small>Pricing</Paragraph>
            </Link> */}
            </List>

            <List>
              <a href="https://www.facebook.com/codewellgroup" target="_blank" rel="noreferrer" >
                <Paragraph small>Facebook</Paragraph>
              </a>
              <a href="https://www.instagram.com/codewellgroup/" target="_blank" rel="noreferrer" >
                <Paragraph small>Instagram</Paragraph>
              </a>
              <a href="https://twitter.com/wearecodewell" target="_blank" rel="noreferrer" >
                <Paragraph small>Twitter</Paragraph>
              </a>
              <a href="https://www.linkedin.com/company/codewell/mycompany/" target="_blank" rel="noreferrer" >
                <Paragraph small>Linkedin</Paragraph>
              </a>

            </List>
          </Links>
        </FooterTop>
        <FooterBottom>
          <Paragraph small>
            Copyright &copy; {new Date().getFullYear()} <span>CodeWell.AI</span>{" "}
            All rights reserved.
          </Paragraph>
        </FooterBottom>
      </Container>
    </FooterComponent>
  );
};

export default Footer;

const FooterComponent = styled.footer`
  position: relative;
  padding: 2.4rem 0;
  background-color: #0f1b1c;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10;
`;

const Paragraph = styled(P)``;

const FooterTop = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Links = styled.div`
  display: flex;
  justify-self: flex-end;
`;

const List = styled.div`
  &:nth-of-type(1) {
    margin-right: 8rem;
  }

  ${Paragraph} {
    padding: 0.8rem 0;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #aed9aa;
    }
  }
`;

const FooterBottom = styled.div``;
